<template>
  <div>
    <SectionCard label="Пользователи подрядной организации" class="mb-0">
      <AccountUsersList
        :headers="[
          { text: 'ID пользователя', value: 'user.id', alias: 'userId', sortable: true },
          { text: 'Дата создания', value: 'user.dateCreate', alias: 'dateCreate', sortable: true },
          { text: 'ФИО', value: 'user.fio', alias: 'fio', sortable: false },
          { text: 'Должность', value: 'user.position', alias: 'position', sortable: false },
          { text: 'Статус', value: 'status.title', alias: 'status', options: values(statuses), sortable: false, multiple: false },
        ]"
        :items="list.items"
        :count="list.navCount"
        :page="page"
        :size="size"
        :loading="loading"
      />
    </SectionCard>
    <VFooter app>
      <div class="py-3 grow">
        <VBtn color="primary" class="mr-2" depressed @click="onCreate">Создать нового</VBtn>
        <VBtn color="primary" class="mr-2" depressed @click="onCreateFromDatabase">Создать из справочника</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>

import SectionCard from '@/components/user/SectionCard/SectionCard.vue';
import AccountUsersList from '@/components/account-users/AccountUsers/components/AccountUsersList.vue';
import {mapActions, mapGetters} from 'vuex';
import {
  ACCOUNT_USERS_STATUSES,
} from '@/store/account-users/enums';
import {debounce, values} from 'lodash-es';

export default {
  name: 'AccountUsers',
  components: { AccountUsersList, SectionCard },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'accountUsers/pending',
      list: 'accountUsers/list',
    }),
    statuses() {
      return ACCOUNT_USERS_STATUSES
    },
  },
  methods: {
    values,
    ...mapActions({
      fetchAccountUsersList: 'accountUsers/getAccountUsersList',
    }),
    onUpdateState: debounce(function () {
      this.update();
    }, 500),
    update() {
      const { page, size, filter } = this;
      return this.fetchAccountUsersList({ page, size, filter });
    },
    onCreate() {
      this.$router.push({ name: 'account-users/AccountUsersCreateView' })
    },
    onCreateFromDatabase() {
      this.$router.push({ name: 'account-users/AccountUsersCreateView', query: { fromDatabase: true } })
    }
  },
  watch: {
    page: function () {
      this.onUpdateState();
    },
    size: function () {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function () {
        this.onUpdateState();
      }
    }
  },
  beforeMount() {
    this.update();
  }
}
</script>
